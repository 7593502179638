<template>
  <!-- <AppLayout> -->
    <keep-alive>
      <router-view></router-view>
    </keep-alive>
  <!-- </AppLayout> -->
</template>

<script>

import { createNamespacedHelpers } from 'vuex';
const { mapActions, mapState } = createNamespacedHelpers('attendences');

export default {
  methods: {
    ...mapActions(['getAttendenceFinished'])
  },
  async created () {
    this.finishedAttendences = await this.getAttendenceFinished();
  },
  
};
</script>

<style>
</style>
